
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.headerUserDrop {
  display: flex;
  flex-direction: column;
  align-items: center;

  .userButtonWrapper .userProfileButton {
    display: flex;
    align-items: center;

    @include desktop {
      flex-direction: row;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  @include desktop {
    position: relative;
    width: fit-content;

    &.is-open .userOptionsContainer {
      display: block;
    }

    .userOptionsContainer {
      display: none;
      background-color: rgb(var(--white));
      border-radius: var(--border-radius);
      box-shadow: var(--shadow-level-3);
      position: absolute;
      top: 100%;
    }

    .userButtonWrapper {
      height: 100%;

      .userProfileButton {
        height: 100%;
        padding: 0 10px;

        &.hoverEffects {
          &:hover,
          &:focus {
            background-color: rgb(var(--snow));
          }
        }
      }
    }
  }

  @include mobile {
    display: contents;

    .userOptionsContainer {
      display: contents;
    }

    .userButtonWrapper {
      display: flex;
      justify-content: center;
      order: 2;
      flex-basis: 100%;
      margin: 25px 0;
      position: relative;

      &.is-inline-for-user-content {
        flex-basis: 22%;
        margin: 50px 0 25px auto;

        .userProfileButton {
          margin: 0 auto 0 0;
        }
      }
    }
  }
}
