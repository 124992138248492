
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '../../../scss-variables.scss';

.profileBoxText {
  color: rgb(var(--jet));
  font-size: var(--font-size-s);

  @include desktop {
    margin-right: 5px;
    font-weight: 400;
  }

  @include mobile {
    margin-top: 10px;
    font-weight: var(--font-weight-medium);
  }

  &.showInMobile {
    /* stylelint-disable-next-line custom/enforce-breakpoint-mixin */
    @media screen and (max-width: #{$wide-desktop-for-buttons-breakpoint}) and (min-width: #{$mobile-for-buttons-breakpoint}) {
      display: none;
    }
  }

  &:not(.showInMobile) {
    /* stylelint-disable-next-line custom/enforce-breakpoint-mixin */
    @media screen and (max-width: #{$wide-desktop-for-buttons-breakpoint}) {
      display: none;
    }
  }
}
